<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit"> 编辑VIP限时活动 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain
					v-if="$hasAccess('vip.vipcfg/editxshd')">保存 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<!-- 主栏 -->
					<el-col :xs="24" :sm="16">
						<!-- VIP限时活动 -->
						<div class="el-block">
							<div class="row-title">VIP限时活动设置</div>
							<div class="coupon-choose-form">
								<el-tabs v-model="editableTabsValue" type="card" editable @edit="handleTabsEdit">
									<el-tab-pane :key="item.name" v-for="(item, index) in editableTabs"
										:label="'达到'+item.electric+'度'" :name="item.name">
										<div class="coupon-item">
											<span class="tips">VIP周期内总电量达到</span>
											<el-input-number type="number" :min="10" :max="10000" :step="10"
												:precision="0" v-model="item.electric" class="input" size="small"
												placeholder="请输入电量"></el-input-number>
											<span class="tips">度</span>
										</div>

										<div class="title">
											<span class="tips"></span>
											<el-button class="add-button" icon="el-icon-plus" type="primary"
												size="small" @click="onItemAdd(item.list)">添加奖券</el-button>
										</div>
										<div v-for="idx in item.list.length" class="coupon-item">
											<span class="tips">选择奖券</span>
											<el-select v-model="item.list[idx-1].coupon" class="select" size="small"
												placeholder="请选择奖券" clearable>
												<el-option v-for="item in couponList" :key="item.coupon_id"
													:label="item.coupon_name" :value="item.coupon_id">
												</el-option>
											</el-select>
											<span class="tips">数量</span>
											<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
												v-model="item.list[idx-1].num" class="input" size="small"
												placeholder="请输入数量"></el-input-number>
											<el-button class="del-button" size="small" type="danger"
												icon="el-icon-delete" @click="onItemDel(item.list,idx-1)">删除
											</el-button>
										</div>

									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'vip-vipzshd-edit',
		components: {},
		data() {
			return {
				fieldCode: 'vip_xshd',
				// 表单信息
				formData: {},
				// 表单规则
				rules: {},
				plug_ids: "",
				couponList: [],
				editableTabsValue: '1',
				editableTabs: [],
				tabIndex: 0
			}
		},
		mounted() {
			this.init()
		},
		async activated() {
			this.init()
		},
		methods: {
			init() {
				this.getCouponList();
				this.getVipcfg();
			},
			handleTabsEdit(targetName, action) {
				if (action === 'add') {
					let newTabName = ++this.tabIndex + '';
					this.editableTabs.push({
						electric: '0',
						name: newTabName,
						list: [{
							"coupon": null,
							"num": 1
						}]
					});
					this.editableTabsValue = newTabName;
				}
				if (action === 'remove') {
					let tabs = this.editableTabs;
					let activeName = this.editableTabsValue;
					if (activeName === targetName) {
						tabs.forEach((tab, index) => {
							if (tab.name === targetName) {
								let nextTab = tabs[index + 1] || tabs[index - 1];
								if (nextTab) {
									activeName = nextTab.name;
								}
							}
						});
					}
					this.editableTabsValue = activeName;
					this.editableTabs = tabs.filter(tab => tab.name !== targetName);
				}
			},
			// 获取优惠券信息
			async getCouponList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				};
				const res = await this.$api.Market.GetCouponList(params, {});
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;
			},
			//获取配置
			async getVipcfg() {
				const params = {
					token: this.$store.state.user.token,
					fieldCode: this.fieldCode
				};
				const res = await this.$api.Vip.VipcfgIndex(params);
				this.editableTabs = res.fieldValue;
				this.tabIndex = res.fieldValue.length;
			},
			// 保存
			save(formName) {
				//if (!this.checkItem()) return;
				this.$refs[formName].validate(async valid => {
					if (valid) {
						this.formData.fieldValue = JSON.stringify(this.editableTabs);
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.formData)
						}
						await this.$api.Vip.VipcfgEditXshd(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.getVipcfg();
					}
				})
			},
			//增加限时活动
			onItemAdd: function(item) {
				item.push({
					"coupon": null,
					"num": 1
				});
			},
			//删除限时活动
			onItemDel: function(item, idx) {
				item.splice(idx, 1)
			},
			//检测限时活动参数
			checkItem: function() {
				for (let i = 0; i < this.editableTabs.length; ++i) {
					for (let j = 0; j < this.editableTabs[i].list.length; ++j) {
						if (!this.editableTabs[i].list[j][0]) {
							return false;
						}
					}
				}
				return true;
			}
		},
	}
</script>
<style lang="scss" scoped>
	.coupon-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.tips {
			font-size: 14px;
			color: #606266;
			padding: 6px 6px;
		}

		.coupon-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 180px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.coupon-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
